%two-columns {
  > div {
    h3 {
      text-align: center;
    }

    img {
      display: block;
      margin: 0 auto 1rem auto;
    }

    &:first-child {
      margin-bottom: 2rem;
    }

    &:last-child {
      h3 {
        margin-top: 3rem;
      }
    }
  }

  // Desktop
  @media (#{$bp-larger-than-tablet}) {
    display: flex;

    > div {
      flex-basis: 0;
      flex-grow: 1;

      &:first-child {
        margin-bottom: 0;
        margin-right: 1rem;
        padding-right: 1rem;
      }

      &:last-child {
        margin-left: 1rem;
        padding-left: 1rem;

        h3 {
          margin-top: 0;
        }
      }
    }
  }
}
